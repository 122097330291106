/* This is a temporary implementation, due to existing endpoints we need to map multiple endpoints from different prefixes */
import { client } from "#src/apis/axios";
import {
  ChannelImportItem,
  ChannelImportStatus,
  GetChannelImportsResponse,
} from "#src/types/import";
import { EmailFile, EmailFileStatus, GetEmailFilesResponse } from "#src/types/invite";
import {
  GetOauthImportsForChannelResponse,
  OauthImportItem,
  OauthImportStatus,
  OauthProvider,
} from "#src/types/oauth";

export const getChannelImports = async (
  channelId: number
): Promise<GetChannelImportsResponse | undefined> => {
  const emailFiles = client.get<GetEmailFilesResponse>(
    `/community/email-files/channel/${channelId}`
  );
  const oauthImports = client.get<GetOauthImportsForChannelResponse>(
    `/community/oauth-email-aggregate/channel/${channelId}`
  );

  const [{ data: emailFilesResponse }, { data: oauthImportsResponse }] =
    await Promise.all([emailFiles, oauthImports]);

  const mappedEmailFiles = emailFilesResponse.data.map((emailFile) => {
    const oauthImport = oauthImportsResponse.data.find(
      (item) => item.emailFileId === emailFile.id
    );

    return {
      fileId: emailFile.id,
      oauthId: oauthImport?.id,
      channelId,
      parsedEmailCount: oauthImport?.parsedEmailCount ?? 0,
      type: oauthImport?.type || ("file" as OauthProvider | "file"),
      status: mapChannelImportStatus(emailFile.status, oauthImport?.status),
    };
  });

  const mappedOauthImports = oauthImportsResponse.data.map((oauthImport) => ({
    fileId: oauthImport.emailFileId,
    oauthId: oauthImport.id,
    type: oauthImport.type,
    channelId,
    parsedEmailCount: oauthImport.parsedEmailCount ?? 0,
    status: mapChannelImportStatus(undefined, oauthImport.status),
  }));

  // combine both mapped responses and remove duplicates
  const combinedImports: ChannelImportItem[] = [
    ...mappedEmailFiles,
    ...mappedOauthImports.filter(
      (oauthImport) =>
        !mappedEmailFiles.find((emailFile) => emailFile.oauthId === oauthImport.oauthId)
    ),
  ];

  return { data: combinedImports };
};

export const getChannelPolledImportStatus = async ({
  status,
  fileId,
  oauthId,
}: ChannelImportItem): Promise<ChannelImportStatus> => {
  try {
    if (oauthId && status === ChannelImportStatus.OauthPending) {
      const { data } = await client.get<OauthImportItem>(
        `/community/oauth-email-aggregate/${oauthId}`
      );
      return mapChannelImportStatus(undefined, data.status);
    }
    if (fileId && status === ChannelImportStatus.FilePending) {
      const { data } = await client.get<EmailFile>(`/community/email-files/${fileId}`);
      return mapChannelImportStatus(data.status);
    }

    return status;
  } catch (error) {
    return status;
  }
};

export const mapChannelImportStatus = (
  emailFileStatus?: EmailFileStatus,
  oauthStatus?: OauthImportStatus
): ChannelImportStatus => {
  if (emailFileStatus === EmailFileStatus.Cancelled) {
    return ChannelImportStatus.FileCancelled;
  }
  if (emailFileStatus === EmailFileStatus.Pending) {
    return ChannelImportStatus.FilePending;
  }
  if (emailFileStatus === EmailFileStatus.Processed) {
    return ChannelImportStatus.FileProcessed;
  }
  if (emailFileStatus === EmailFileStatus.Failed) {
    return ChannelImportStatus.FileFailed;
  }
  if (oauthStatus === OauthImportStatus.Pending) {
    return ChannelImportStatus.OauthPending;
  }
  if (oauthStatus === OauthImportStatus.Processed) {
    return ChannelImportStatus.OauthProcessed;
  }
  if (oauthStatus === OauthImportStatus.Failed) {
    return ChannelImportStatus.OauthFailed;
  }

  return ChannelImportStatus.NotStarted;
};
