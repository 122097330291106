import { QUERY_TAGS } from "#src/constants/query";
import env from "#src/env";
import { channelImportsApi } from "#src/features/channel/channelImportsAPI";
import { appBaseQuery } from "#src/features/common/baseQuery";
import {
  GetMailchimpListsRequest,
  GetMailchimpListsResponse,
  GetMailchimpListsSegmentsResponse,
  GetMailchimpSegmentsRequest,
  GetMailchimpSegmentsResponse,
  MailchimpImportRequest,
} from "#src/types/oauth";
import createApi from "../../../createApi";

export const mailchimpApi = createApi({
  reducerPath: "mailchimp",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/oauth/mailchimp/`,
  }),
  tagTypes: [QUERY_TAGS.ChannelImports],
  endpoints: (builder) => ({
    fetchMailchimpLists: builder.query<
      GetMailchimpListsResponse,
      GetMailchimpListsRequest
    >({
      query: ({ organisationId }) => ({
        url: `get-lists`,
        method: "GET",
        params: {
          organisationId,
        },
        credentials: "include",
      }),
    }),
    fetchMailchimpSegments: builder.query<
      GetMailchimpSegmentsResponse,
      GetMailchimpSegmentsRequest
    >({
      query: ({ organisationId }) => ({
        url: `get-segments`,
        method: "GET",
        params: {
          organisationId,
        },
        credentials: "include",
      }),
      keepUnusedDataFor: 0,
    }),
    fetchMailchimpListsSegments: builder.query<
      GetMailchimpListsSegmentsResponse,
      GetMailchimpListsRequest
    >({
      query: ({ organisationId }) => ({
        url: `get-lists-segments`,
        method: "GET",
        params: {
          organisationId,
        },
        credentials: "include",
      }),
      keepUnusedDataFor: 0,
    }),
    requestMailchimpImport: builder.mutation<void, MailchimpImportRequest>({
      query: (body) => ({
        url: `import`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async ({ channelId }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          channelImportsApi.util.invalidateTags([
            { type: QUERY_TAGS.ChannelImports, id: channelId },
          ])
        );
      },
    }),
  }),
});

export const {
  useFetchMailchimpListsQuery,
  useFetchMailchimpSegmentsQuery,
  useFetchMailchimpListsSegmentsQuery,
  useRequestMailchimpImportMutation,
} = mailchimpApi;
