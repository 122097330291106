import { OauthProvider } from "#src/types/oauth";

export enum ChannelImportStatus {
  NotStarted = "FILE_NOT_STARTED",
  OauthPending = "OAUTH_PENDING",
  OauthProcessed = "OAUTH_PROCESSED",
  OauthFailed = "OAUTH_FAILED",
  FilePending = "FILE_PENDING",
  FileProcessed = "FILE_PROCESSED",
  FileFailed = "FILE_FAILED",
  FileCancelled = "FILE_CANCELLED",
}

export interface ChannelImportItem {
  channelId: number;
  fileId?: string;
  oauthId?: string;
  type?: OauthProvider | "file";
  parsedEmailCount: number;
  status: ChannelImportStatus;
}

export interface GetChannelImportsResponse {
  data: ChannelImportItem[];
}
