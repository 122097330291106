import { QUERY_TAGS } from "#src/constants/query";
import env from "#src/env";
import { channelImportsApi } from "#src/features/channel/channelImportsAPI";
import { appBaseQuery } from "#src/features/common/baseQuery";
import {
  GetSalesforceListsRequest,
  GetSalesforceListsResponse,
  SalesforceImportRequest,
} from "#src/types/oauth";
import createApi from "../../../createApi";

export const salesforceApi = createApi({
  reducerPath: "salesforce",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/oauth/salesforce/`,
  }),
  tagTypes: [QUERY_TAGS.ChannelImports],
  endpoints: (builder) => ({
    fetchSalesforceLists: builder.query<
      GetSalesforceListsResponse,
      GetSalesforceListsRequest
    >({
      query: ({ organisationId }) => ({
        url: `get-list-views`,
        method: "GET",
        params: {
          organisationId,
        },
        credentials: "include",
      }),
      keepUnusedDataFor: 0,
    }),
    requestSalesforceImport: builder.mutation<void, SalesforceImportRequest>({
      query: (body) => ({
        url: `import`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async ({ channelId }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(
          channelImportsApi.util.invalidateTags([
            { type: QUERY_TAGS.ChannelImports, id: channelId },
          ])
        );
      },
    }),
  }),
});

export const { useFetchSalesforceListsQuery, useRequestSalesforceImportMutation } =
  salesforceApi;
